<template>
  <b-form-group
    label="Güncel Alış Fiyatı"
    label-for="amountPrice"
  >
    <!--    <validation-provider-->
    <!--      #default="{ errors }"-->
    <!--      name="Güncel Alış Fiyatı"-->
    <!--      rules="required"-->
    <!--    >-->
    <b-input-group append="TL">
      <cleave
        id="amountPrice"
        v-model="dataItem.amountPrice"
        class="form-control"
        :raw="true"
        :options="options"
        placeholder="Giriniz"
      />
    </b-input-group>
    <!--      <small class="text-danger">{{ errors[0] }}</small>-->
    <div>
      <small class="text-muted">Son alım fiyatı ile otomatik olarak güncellenir.</small>
    </div>
    <!--    </validation-provider>-->
  </b-form-group>
</template>
<script>
import { BFormGroup, BInputGroup } from 'bootstrap-vue'
import { localize /* ValidationProvider */ } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'AmountCard',
  components: {
    /* ValidationProvider, */ BFormGroup, BInputGroup, Cleave,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
